import { createSlice } from '@reduxjs/toolkit'

const viewSlice = createSlice({
	name: 'view',
	initialState: {
		screenSize: '',
		hasPopup: false,
	},

	reducers: {
		setScreenSize: (state, { payload }) => {
			state.screenSize = payload
		},
		openPopup: (state) => {
			state.hasPopup = true
			document.body.classList.add('locked')
		},
		closePopup: (state) => {
			state.hasPopup = false
			document.body.classList.remove('locked')
		},
	},
})

export const { setScreenSize, openPopup, closePopup } = viewSlice.actions
export default viewSlice.reducer
