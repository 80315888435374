import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit'
import customImg from '../../Assets/graphics/custom-specs.svg'
import agilityImg from '../../Assets/graphics/agility-preset.svg'
import balancedImg from '../../Assets/graphics/balanced-preset.svg'
import powerImg from '../../Assets/graphics/speed-preset.svg'

import ccmImg from '../../Assets/photos/speedblade-xs.jpg'
import bauerImg from '../../Assets/photos/bauer-tuuk.jpg'
import trueImg from '../../Assets/photos/true-shift.jpg'
import beam from '../../Handlers/beam'
import { State } from '../store'

export const fetchPresetOptions = createAsyncThunk(
	'presetOptions/fetch',
	async () => {
		const optionsRes = await beam.get('/api/fetchAllBasicPresets')
		const options = optionsRes.response
		return { presetOptions: options.data }
	}
)

export const fetchSettingsOptions = createAsyncThunk(
	'settingsOptions/fetch',
	async () => {
		const optionsRes = await beam.get('/api/fetchSettings')
		const options = optionsRes.response
		return { settingsOptions: options }
	}
)

export const fetchPlayerImageOptions = createAsyncThunk(
	'imageOptions/fetch',
	async () => {
		const optionsRes = await beam.get('/api/fetchAllowedProfileImages')
		const options = optionsRes.response
		return { imageOptions: options }
	}
)

export const fetchLocationOptions = createAsyncThunk(
	'locations/fetch',
	async () => {
		const optionsRes = await beam.get('/api/fetchAllLocations')
		const options = optionsRes.response

		return { locationOptions: options.data }
	}
)

export enum SpecType {
	Agility = 'Agility',
	Balanced = 'Balanced',
	Speed = 'Speed',
	Custom = 'Custom',
	Advanced = 'Advanced',
}
export type PresetSpecs = SpecType.Agility | SpecType.Balanced | SpecType.Speed
export interface PresetOption {
	basicPresetID?: number
	name: PresetSpecs
	default?: boolean
	hollowValue?: string
	pitchValue?: number
	radiusValue?: number
	imageURL: string
}
const presetOptions: PresetOption[] = []

export interface Location {
	locationID: number
	lat: number
	lng: number
	address: string
	link: string
	title: string
	iframe: string
	openingHours?: [{ days: string; time: string }]
}

export interface ImageOption {
	profileImageId: number
	uri: string
	name: string
}

const locations: Location[] = []

interface SettingsOption {
	name: string
	options: string[]
}
const settingsOptions: SettingsOption[] = []

const optionsSlice = createSlice({
	name: 'products',
	reducers: {},
	initialState: {
		presetImages: {
			[SpecType.Agility]: agilityImg,
			[SpecType.Balanced]: balancedImg,
			[SpecType.Speed]: powerImg,
			[SpecType.Custom]: customImg,
		},
		presetOptions,
		specOptions: {
			hollow: {
				options: [
					'3/8',
					'7/16',
					'1/2',
					'9/16',
					'5/8',
					'11/16',
					'3/4',
					'13/16',
					'7/8',
					'15/16',
					'1',
				],
			},

			radius: {
				range: [5, 15],
			},

			pitch: {
				range: [-10, 10],
				customRange: [-30, 30],
			},
		},

		bootTypes: {
			ccmSpeedBlade: { name: 'CCM Speedblade XS', imageURL: ccmImg },
			bauerTUUK: { name: 'Bauer TUUK', imageURL: bauerImg },
			trueShift: { name: 'True Shift', imageURL: trueImg },
		},

		bootSizes: [
			'212',
			'221',
			'230',
			'238',
			'246',
			'254',
			'263',
			'272',
			'280',
			'288',
			'296',
			'306',
		],

		locations,

		shippingPrices: {
			express: '1700',
			economy: '500',
		},

		settingsOptions,
		imageOptions: [],
	},
	extraReducers: (builder) => {
		builder.addCase(
			fetchSettingsOptions.fulfilled,
			(state: any, { payload }: any) => {
				state.settingsOptions = payload.settingsOptions
			}
		)
		builder.addCase(
			fetchPlayerImageOptions.fulfilled,
			(state: any, { payload }: any) => {
				state.imageOptions = payload.imageOptions
			}
		)
		builder.addCase(
			fetchLocationOptions.fulfilled,
			(state: any, { payload }: any) => {
				state.locations = payload.locationOptions
			}
		)
		builder.addCase(
			fetchPresetOptions.fulfilled,
			(state: any, { payload }: any) => {
				state.presetOptions = payload.presetOptions
			}
		)
	},
})

//selectors

export const bootTypesSelector = (s: State) => s.options.bootTypes
export const bootSizeSelector = (s: State) => s.options.bootSizes

export const hollowSelector = (s: State) => s.options.specOptions.hollow.options
export const radiusSelector = (s: State) => s.options.specOptions.radius
export const pitchSelector = (s: State) => s.options.specOptions.pitch

export const imageSelector = createSelector(
	(s: State) => s.options.imageOptions,
	(imageOptions: ImageOption[]) => {
		return imageOptions.filter((option) => option.name !== 'Default')
	}
)
export const defaultImageUrlSelector = createSelector(
	(s: State) => s.options.imageOptions,
	(imageOptions: ImageOption[]) => {
		const defaultImage = imageOptions.find(
			(option) => option.name === 'Default'
		)
		return defaultImage?.uri
	}
)
export const defaultImageIDSelector = createSelector(
	(s: State) => s.options.imageOptions,
	(imageOptions: ImageOption[]) => {
		const defaultImage = imageOptions.find(
			(option) => option.name === 'Default'
		)
		return defaultImage?.profileImageId
	}
)

export const defaultPresetImageSelector = createSelector(
	(s: State) => s.options.imageOptions,
	(imageOptions: ImageOption[]) => {
		return imageOptions.find((option) => option.name === 'Skatescribe')
	}
)

export const presetImageSelector = (s: State) => s.options.presetImages
export const presetSelector = createSelector(
	(s: State) => ({
		images: s.options.presetImages,
		presets: s.options.presetOptions,
	}),
	({ images, presets }) =>
		presets.map((preset: PresetOption) => ({
			...preset,
			imageURL: images[preset?.name as PresetSpecs],
		}))
)
export default optionsSlice.reducer
