import firebase from 'firebase/app'
import 'firebase/auth'
import Beam from 'browser-beam'

async function tokenBuilder(): Promise<string> {
	const token = await firebase
		.auth()
		.currentUser?.getIdToken()
		.catch(() => '')
	return token || ''
}

const beam = new Beam({
	tokenBuilder: tokenBuilder,
	urlPrefix: process.env.REACT_APP_SERVER_URL,
})

export default beam
