import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import beam from '../../Handlers/beam'

export interface UserPresetsState {
	list: UserPreset[]
	loading: boolean
	currentPreset: Partial<UserPreset> | null
}

const UserPresetsInitialState: UserPresetsState = {
	list: [],
	loading: true,
	currentPreset: null,
}

export interface UserPreset {
	presetID?: number
	userID?: string
	createdDate?: string
	presetName: string
	imageID: number
	imagePath?: string
	hollowValue: string
	pitchValue: number
	radiusValue: number
	lastUsed?: string
}

export enum PresetsSortType {
	DateAddedFirst = 'Date Added (first)',
	DateAddedLast = 'Date Added (last)',
	AlphabeticallyFirst = 'Alphabetically (first)',
	AlphabeticallyLast = 'Alphabetically (last)',
}

export const createUserPreset = createAsyncThunk(
	'presets/create',
	async (presetData: UserPreset) => {
		const presetRes = await beam.post('/api/createPreset/', presetData)
		return {
			preset: { ...presetRes.response.data, imageID: presetData.imagePath },
		}
	}
)

export const fetchUserPresets = createAsyncThunk('presets/fetch', async () => {
	const presetsRes = await beam.get('/api/getPresets/')
	return { presets: presetsRes.response.data }
})

interface UpdatePresetProps {
	presetID: number
	updates: {
		presetName?: string
		// imageID?: number;
		hollowValue?: string
		pitchValue?: number
		radiusValue?: number
	}
}
export const updateUserPreset = createAsyncThunk(
	'presets/update',
	async (preset: UpdatePresetProps) => {
		const presetRes = await beam.post('/api/updatePreset', { ...preset })
		return { preset: presetRes.response.data }
	}
)

export const updatePresetImage = createAsyncThunk(
	'presets/updateImage',
	async ({
		presetID,
		imageID,
		imagePath,
	}: {
		presetID: number
		imageID: number
		imagePath: string
	}) => {
		await beam.post('/api/updatePreset', {
			presetID,
			updates: {
				imageID,
			},
		})
		return { presetID, imagePath }
	}
)

export const deleteUserPreset = createAsyncThunk(
	'presets/delete',
	async (presetID: number) => {
		await beam.post('/api/deletePreset', { presetID })
		return { presetID }
	}
)

const presetsSlice = createSlice({
	name: 'presets',
	reducers: {
		updateCurrentPreset: (state, action) => {
			state.currentPreset = { ...state.currentPreset, ...action.payload }
		},
		setCurrentPresetNull: (state) => {
			state.currentPreset = null
		},
	},
	initialState: UserPresetsInitialState,
	extraReducers: (builder) => {
		builder.addCase(
			createUserPreset.fulfilled,
			(state: UserPresetsState, { payload }: any) => {
				state.list = [...state.list, payload.preset]
			}
		)
		builder.addCase(fetchUserPresets.pending, (state: UserPresetsState) => {
			state.loading = true
		})
		builder.addCase(fetchUserPresets.rejected, (state: UserPresetsState) => {
			state.loading = false
		})
		builder.addCase(
			fetchUserPresets.fulfilled,
			(state: UserPresetsState, { payload }: any) => {
				state.list = payload.presets
				state.loading = false
			}
		)
		builder.addCase(
			deleteUserPreset.fulfilled,
			(state: UserPresetsState, { payload }: any) => {
				const { presetID }: { presetID: number } = payload
				state.list = [...state.list].filter(
					(preset) => preset.presetID != presetID
				)
			}
		)
		builder.addCase(
			updateUserPreset.fulfilled,
			(state: UserPresetsState, { payload }: any) => {
				state.list = [...state.list].map((preset) =>
					preset.presetID == payload.preset.presetID
						? { ...payload.preset, imageID: preset.imageID }
						: preset
				)
			}
		)
		builder.addCase(
			updatePresetImage.fulfilled,
			(state: UserPresetsState, { payload }: any) => {
				state.list = [...state.list].map((preset) =>
					preset.presetID == payload.presetID
						? { ...preset, imageID: payload.imagePath }
						: preset
				)
			}
		)
	},
})

export const { updateCurrentPreset, setCurrentPresetNull } =
	presetsSlice.actions
export default presetsSlice.reducer
