import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import beam from '../../Handlers/beam'
import Order, { OrderStatus } from '../../@types/Order'
import { State } from '../store'
import { SpecType } from './optionsSlice'

interface OrderState {
	loading?: boolean
	list: Order[]
	moreOrders?: boolean
}

export const fetchOrders = createAsyncThunk('orders/fetch', async () => {
	const fetchRes = await beam.post('/api/fetchAllOrders').catch((err) => {
		return Promise.reject(err)
	})

	return {
		list: fetchRes.response.data,
	}
})

export const fetchOrderByPage = createAsyncThunk(
	'orders/fetchByPage',
	async (page?: number) => {
		const fetchRes = await beam
			.post('/api/fetchOrdersByPage', {
				page: page || 1,
			})
			.catch((err) => {
				return Promise.reject(err)
			})

		return {
			list: fetchRes.response.data,
			page: page || 1,
		}
	}
)

const ordersSlice = createSlice({
	name: 'orders',
	initialState: {
		list: [],
		loading: true,
		moreOrders: false,
	},
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchOrders.pending, (state: OrderState) => {
			state.loading = true
		})
		builder.addCase(fetchOrders.rejected, (state: OrderState) => {
			state.loading = false
		})
		builder.addCase(
			fetchOrders.fulfilled,
			(state: OrderState, { payload }: { payload: OrderState }) => {
				state.loading = false
				state.list = payload.list
			}
		)
		builder.addCase(fetchOrderByPage.pending, (state: OrderState) => {
			state.loading = true
		})
		builder.addCase(fetchOrderByPage.rejected, (state: OrderState) => {
			state.loading = false
		})
		builder.addCase(
			fetchOrderByPage.fulfilled,
			(
				state: OrderState,
				{ payload }: { payload: { list: Order[]; page: number } }
			) => {
				state.loading = false
				state.list =
					payload.page === 1 ? payload.list : [...state.list, ...payload.list]
				// if the length of payload is less than 50, then there are no more orders
				state.moreOrders = payload.list.length === 50
			}
		)
	},
})

//Selectors
export const openOrdersSelector = createSelector(
	(s: State) => s.orders.list,
	(list) => {
		return list.filter(
			(order: any) =>
				![OrderStatus.Complete, OrderStatus.Cancelled].includes(order.status)
		)
	}
)

export const specsHistorySelector = createSelector(
	(s: State) => s.orders.list,
	(list) => {
		return list
			.filter((order: Order) => {
				return order.content.some(
					(item: any) => item.specType !== SpecType.Custom
				)
			})
			.map((order: Order) => {
				const customOrderItems = order.content.filter(
					(item: any) => item.specType !== SpecType.Custom
				)
				return customOrderItems.map((item: any) => {
					return { ...item, placedDate: order.placedDate }
				})
			})
			.reduce((acc: any, curr: any) => acc.concat(curr), [])
	}
)

export default ordersSlice.reducer
