import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { UserAccount, UserData } from '../../@types/User'
import beam from '../../Handlers/beam'
import { State } from '../store'

export const updateUserData = createAsyncThunk(
	'activeUser/update',
	async ({
		updates,
		callback,
	}: {
		updates: any
		callback?: { (error?: string): void }
	}) => {
		const dataRes = await beam
			.post('/api/updatePlayer', { updates })
			.catch(({ response }) => {
				callback && callback(response.error)
				return Promise.reject('Unable to update user data!')
			})
		callback && callback()
		return { updates: dataRes.response }
	}
)

export const updateUserSettingsData = createAsyncThunk(
	'activeUserSettings/update',
	async (settings: any) => {
		const settingsRes = await beam
			.post('/api/updatePlayersSettings', { settings })
			.catch(() => {
				return Promise.reject('Unable to update user settings!')
			})
		return { settings: settingsRes.response }
	}
)

export const updatePlayerImage = createAsyncThunk(
	'activeUserImage/update',
	async (profileImageId: number) => {
		const imageRes = await beam
			.post('/api/updatePlayerImage', { profileImageId })
			.catch(() => {
				return Promise.reject('Unable to update profile image!')
			})
		return { imageURL: imageRes.response.imageURL }
	}
)

const userData: Partial<UserData> = {}
const userAccount: Partial<UserAccount> = {}
const activeUserSlice = createSlice({
	name: 'activeUser',

	initialState: {
		status: 'Loading',
		account: userAccount,
		data: userData,
	},

	reducers: {
		setAccount: (state: any, action: any) => {
			if (action.payload?.uid) {
				state.account = action.payload
				state.status = 'Logged In'
			} else {
				state.account = {}
				state.status = 'Logged Out'
			}
		},

		setUserData: (state: any, { payload }: any) => {
			state.data = payload
		},
	},

	extraReducers: (builder) => {
		builder.addCase(
			updateUserData.fulfilled,
			(state: any, { payload }: any) => {
				state.data = { ...state.data, ...payload.updates }
			}
		)
		builder.addCase(
			updateUserSettingsData.fulfilled,
			(state: any, { payload }: any) => {
				state.data = { ...state.data, ...payload.settings }
			}
		)
		builder.addCase(
			updatePlayerImage.fulfilled,
			(state: any, { payload }: any) => {
				state.data = { ...state.data, imageURL: payload.imageURL }
			}
		)
	},
})

export const previewsSelector = (s: State) =>
	s.activeUser.data.notificationPreviews

export const { setAccount, setUserData } = activeUserSlice.actions
export default activeUserSlice.reducer
