export default interface Order {
	orderID: string
	shortID: string
	status: OrderStatus
	finalTotal: number
	total: number
	tax: number
	discountTotal?: number
	placedDate: Date
	itemCount: number
	pickupMessage: string
	logs: any[]
	content: OrderItem[]
	contents: any[]
	dropOffLocation: string
	flagged: boolean
	discountCode?: string
	longID: string
	cardDetails: {
		last_four: string
		card_type: string
	}
}

export interface OrderItem {
	hasCustomHollow: boolean
	hasCustomRadius: boolean
	hasCustomPitch: boolean
	hollowValue: string
	pitchValue: number
	radiusValue: number
	type: string
	playerEmail: string
	playerName: string
	playerImageURL: string
	quantity: number
	id: string
	specType: string
	unitPrice: number
	price: number
}

/*
	Order of this enum determines the order
	of 'Order Status' sort in My Orders page
*/
export enum OrderStatus {
	Pending = 'Pending Review',
	ContactCustomer = 'Contact Customer',
	WaitingBlades = 'Waiting for Blades',
	InProgress = 'In Progress',
	Ready = 'Ready For Pickup',
	Complete = 'Order Complete',
	Cancelled = 'Order Cancelled',
}

export enum OrderSortType {
	DateFirst = 'Date (first)',
	DateLast = 'Date (last)',
	PriceLow = 'Price (lowest)',
	PriceHigh = 'Price (highest)',
	OrderStatus = 'Order Status',
}
