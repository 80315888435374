import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { v4 as uuid } from 'uuid'
import beam from '../../Handlers/beam'
import dayjs from 'dayjs'
import { SpecType } from './optionsSlice'
import { DiscountInterface } from '../../Utils/interfaces'

export interface CartItem {
	advancedNotes?: string
	brand?: string
	error?: string
	hasCustomHollow: boolean
	hasCustomPitch: boolean
	hasCustomRadius: boolean
	hollowValue: string
	pitchValue: number
	radiusValue: number
	id: string
	name: string
	playerEmail: string
	playerImageURL: string
	playerName: undefined
	presetID?: number
	price: number
	productID: number
	productSkuID?: number
	quantity: number
	skuName: string
	specType: SpecType
	type: string // TODO: update this to be enum
	unitPrice: number
}

//Calculates the total cost of everything in the cart by looking at the quantity and unitPrice of each item
function calculatePrice(contents: CartItem[], discountAmount?: number) {
	let total = 0
	const discount = discountAmount || 0

	contents.forEach((c: CartItem) => {
		const addedValue = c.unitPrice * c.quantity
		total = total + addedValue
	})

	const totalAfterDiscount = total - discount
	const tax = Math.round(0.13 * totalAfterDiscount)
	const finalTotal = totalAfterDiscount + tax

	return {
		total,
		tax,
		discount,
		finalTotal,
	}
}

export const fetchCartContents = createAsyncThunk(
	'cart/fetch',
	async (isUser: boolean, thunk: any) => {
		if (isUser) {
			const guestCartContents = [...thunk.getState().cart.contents]
			if (guestCartContents.length) {
				return {
					contents: guestCartContents,
				}
			}

			const cartRes = await beam.post('/api/fetchCart').catch(() => {
				return Promise.reject('Unable to get user cart items!')
			})
			const accountCartContents = cartRes.response.cartContents || []
			if (accountCartContents.length) {
				const priceRes = await beam
					.post('/api/fetchPrices', { items: accountCartContents })
					.catch(() => {
						return Promise.reject('Unable to get prices!')
					})
				const priceList = priceRes.response

				accountCartContents.forEach((item: any, i: number) => {
					const unitPrice = priceList[i].price
					accountCartContents[i].price = item.quantity * unitPrice
					accountCartContents[i].error = priceList[i].error
					accountCartContents[i].brand = priceList[i].brand
					accountCartContents[i].skuName = priceList[i].skuName
				})
			}
			localStorage.setItem('cartContents', JSON.stringify(accountCartContents))
			return {
				contents: accountCartContents,
			}
		} else {
			const cartContentsToken = localStorage.getItem('cartContents')
			return {
				contents: cartContentsToken ? JSON.parse(cartContentsToken) : [],
			}
		}
	}
)

//Add
export const addToCart = createAsyncThunk(
	'cart/add',
	async (addInfo: any, thunk: any) => {
		let { items } = addInfo
		const { useLastConfig, replaceCart } = addInfo
		const newContents = replaceCart ? [] : [...thunk.getState().cart.contents]

		if (useLastConfig) {
			try {
				const lastUsedConfig = await beam.post('/api/getLastConfiguration')
				items = [lastUsedConfig.response]
			} catch (err) {
				console.error(err)
			}
		}

		const priceRes = await beam
			.post('/api/fetchPrices', { items })
			.catch(() => {
				return Promise.reject('Unable to get prices!')
			})

		const priceList = priceRes.response

		const presets = [...thunk.getState().options.presetOptions]
		const defaultPresent = presets.find((preset: any) => preset.default)

		items.forEach((t: any, i: number) => {
			const unitPrice = priceList[i].price

			const imageToPush = t.playerImageURL
				? t.playerImageURL
				: process.env.REACT_APP_FALLBACK_USER_IMAGE

			newContents.push({
				type: t.type,
				playerEmail: t.playerEmail,
				playerName: t.playerName,
				playerImageURL: imageToPush,
				quantity: t.quantity,
				id: uuid(),

				//specs
				specType: t.specType || defaultPresent.name,
				productID: priceList[i].productID,
				productSkuID: t.productSkuID,
				name: t.name || priceList[i].name,
				unitPrice,
				price: t.quantity * unitPrice,
				advancedNotes: t.advancedNotes || '',
				error: priceList[i].error,
				brand: priceList[i].brand,
				skuName: priceList[i].skuName,
				...{
					hasCustomHollow: true,
					hasCustomRadius: true,
					hasCustomPitch: true,
					hollowValue: defaultPresent.hollowValue || '',
					radiusValue: defaultPresent.radiusValue || '',
					pitchValue: defaultPresent.pitchValue || '',
					presetID: t.presetID || null,
				},
			})
		})

		const newContentsToken = JSON.stringify(newContents)
		localStorage.setItem('cartContents', newContentsToken)
		if (thunk.getState().activeUser.status === 'Logged In') {
			beam.post('/api/syncCart', { cartContents: newContentsToken })
		}

		// Saving ids for opening the popups automatically. Frontend only.
		if (newContents[newContents.length - 1].type === 'Purchase') {
			thunk.dispatch(
				setNewBladePurchaseId(newContents[newContents.length - 1].id)
			)
		} else if (newContents[newContents.length - 1].type === 'Sharpening') {
			thunk.dispatch(setNewSharpeningId(newContents[newContents.length - 1].id))
		}

		return {
			contents: newContents,
		}
	}
)

//Remove
export const removeFromCart = createAsyncThunk(
	'cart/remove',
	async (id: string, thunk: any) => {
		const newContents = [...thunk.getState().cart.contents].filter(
			(c) => c.id !== id
		)

		const newContentsToken = JSON.stringify(newContents)
		localStorage.setItem('cartContents', newContentsToken)
		if (thunk.getState().activeUser.status === 'Logged In') {
			beam.post('/api/syncCart', { cartContents: newContentsToken })
		}

		return {
			contents: newContents,
		}
	}
)

interface UpdateCartItemProps {
	id: String
	updates: any
}
export const updateCartItem = createAsyncThunk(
	'cart/updateItem',
	async (updateInfo: UpdateCartItemProps, thunk: any) => {
		const updatedContents = [...thunk.getState().cart.contents]

		const { id, updates } = updateInfo

		const idx = updatedContents.findIndex((c: any) => c.id === id)

		const fullUpdates = { ...updates }

		if (idx < 0) {
			return
		}

		if (updates.specType !== SpecType.Custom) {
			fullUpdates.advancedNotes = ''
		}

		if (fullUpdates.quantity !== undefined) {
			fullUpdates.price = fullUpdates.quantity * updatedContents[idx].unitPrice
		}

		updatedContents[idx] = { ...updatedContents[idx], ...fullUpdates }

		const updatedContentsToken = JSON.stringify(updatedContents)
		localStorage.setItem('cartContents', updatedContentsToken)
		if (thunk.getState().activeUser.status === 'Logged In') {
			beam.post('/api/syncCart', { cartContents: updatedContentsToken })
		}
		return {
			contents: updatedContents,
		}
	}
)

export const clearCart = createAsyncThunk('cart/clear', async () => {
	localStorage.setItem('cartContents', JSON.stringify([]))
	return {
		contents: [],
	}
})

export const fetchLastUsedLocation = createAsyncThunk(
	'lastUsedLocation/fetch',
	async () => {
		const locationRes = await beam.get('/api/getLastLocation').catch(() => {
			return Promise.reject('Unable to update user data!')
		})
		return { dropOffLocation: locationRes.response }
	}
)

export const saveReccomendedSpecs = createAsyncThunk(
	'cart/saveReccomendedSpecsQuestionnaire',
	async ({ responses, recoSpecs }: { responses: any; recoSpecs: any }) => {
		await beam
			.post('/api/saveReccomendedSpecsQuestionnaire', { responses, recoSpecs })
			.catch(() => {
				return Promise.reject('Unable to save recommended specs questionaire')
			})
		return {}
	}
)

//state actions
const pendingAction = (state: any) => {
	state.loading = true
}
const rejectedAction = (state: any) => {
	state.loading = false
}
const fulfilledAction = (state: any, { payload }: any) => {
	state.contents = payload.contents
	state.loading = false

	const cost = calculatePrice(state.contents, state.discount)
	state.total = cost.total
	state.tax = cost.tax
	state.finalTotal = cost.finalTotal
	state.discount = cost.discount
}

const cartSlice = createSlice({
	name: 'cart',

	initialState: {
		contents: [],
		total: 0,
		tax: 0,
		loading: false,
		pickupDate: dayjs().add(4, 'days').format('dddd DD MMMM YYYY'),
		newBladePurchaseId: null,
		newBladeAddedfromPopup: false,
		// To open popup while coming from static site
		newSharpeningId: null,
		newSharpeningAddedfromStaticSite: false,
		//
		contactFirstName: '',
		contactPostalCode: '',
		contactLastName: '',
		contactPhone: '',
		contactEmail: '',
		dropOffLocation: 2,
		// Discount Info.
		discountData: null,
		discount: 0,
	},

	reducers: {
		//update cart
		updateCart: (state: any, action: any) => {
			const updates = action.payload

			Object.keys(updates).forEach((u) => {
				state[u] = updates[u]
			})

			const cost = calculatePrice(state.contents, state.discount)

			state.total = cost.total
			state.tax = cost.tax
			state.finalTotal = cost.finalTotal
			state.discount = cost.discount
		},
		// Set newBladePurchaseId.
		setNewBladePurchaseId: (state: any, action: any) => {
			state.newBladePurchaseId = action.payload
		},

		// Set flag, if the blade is added from popup.
		setIsNewBladeAddedfromPopup: (state: any, action: any) => {
			state.newBladeAddedfromPopup = action.payload
		},

		// Set newSharpeningId.
		setNewSharpeningId: (state: any, action: any) => {
			state.newSharpeningId = action.payload
		},
		// Set flag, sharpening was added while coming from static site.
		setNewSharpeningAddedfromStaticSite: (state: any, action: any) => {
			state.newSharpeningAddedfromStaticSite = action.payload
		},

		// Set discount.
		updateDiscount: (
			state: any,
			action: PayloadAction<{
				discountData: null | DiscountInterface
				discountAmount?: number
			}>
		) => {
			const { discountData, discountAmount } = action.payload
			state.discountData = discountData
			const cost = calculatePrice(state.contents, discountAmount)
			state.total = cost.total
			state.tax = cost.tax
			state.finalTotal = cost.finalTotal
			state.discount = discountAmount || 0
		},
	},

	extraReducers: (builder) => {
		builder.addCase(fetchCartContents.pending, pendingAction)
		builder.addCase(fetchCartContents.rejected, rejectedAction)
		builder.addCase(fetchCartContents.fulfilled, fulfilledAction)

		builder.addCase(addToCart.pending, pendingAction)
		builder.addCase(addToCart.rejected, rejectedAction)
		builder.addCase(addToCart.fulfilled, fulfilledAction)

		builder.addCase(updateCartItem.rejected, rejectedAction)
		builder.addCase(updateCartItem.fulfilled, fulfilledAction)

		builder.addCase(removeFromCart.pending, pendingAction)
		builder.addCase(removeFromCart.rejected, rejectedAction)
		builder.addCase(removeFromCart.fulfilled, fulfilledAction)

		builder.addCase(clearCart.fulfilled, (state: any, { payload }: any) => {
			state.contents = payload.contents
		})

		//fetch cases
		builder.addCase(fetchLastUsedLocation.pending, pendingAction)
		builder.addCase(fetchLastUsedLocation.rejected, rejectedAction)
		builder.addCase(
			fetchLastUsedLocation.fulfilled,
			(state: any, { payload }: any) => {
				state.dropOffLocation = payload.dropOffLocation
			}
		)
	},
})

export const {
	updateCart,
	setNewBladePurchaseId,
	setIsNewBladeAddedfromPopup,
	setNewSharpeningId,
	setNewSharpeningAddedfromStaticSite,
	updateDiscount,
} = cartSlice.actions
export default cartSlice.reducer
